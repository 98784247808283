body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
}

.ui.center.aligned.container {
  margin-top: 4em;
}

p.lead {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  color: #333333;
  line-height: 1.4;
  font-weight: 300;
}

.ui.huge.header {
  font-size: 36px;
}

.ui.inverted.menu {
  border-radius: 0;
  flex-wrap: wrap;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.ui.mobile.only.grid .ui.menu .ui.vertical.menu {
  display: none;
}

.ui.inverted.menu .item {
  color: rgb(157, 157, 157);
  font-size: 16px;
}

.ui.inverted.menu .active.item {
  background-color: #080808;
}

.ui.inverted.menu .active.item > a > i {
  color: #2185d0;
}

.ui.menu > .ui.container > .item > a {
  outline: 0;
}

#logo {
  background-color: #2185d0;
  padding: 5px;
  border-radius: 10px;
}

.adminContentClass {
  width: 90% !important;
}

[data-tooltip]::after {
  /*z-index: 100 !important;*/
  white-space: pre !important;
}